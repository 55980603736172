body {
  margin: 0;
  font-family: "Montserrat" !important;
  background-color: #fff !important;
}

code {
  font-family: "Montserrat" !important;
}

:root {
  --p500: #5c6bc0;
  --p700: #303f9f;
  --p800: #283593;
  --a700: #ffc400;
  font-family: "Montserrat" !important;
}

input,
input::-webkit-input-placeholder {
  font-size: 14px !important;
  line-height: 2.5 !important;
}

.showPosts {
  display: none !important;
}

.nav-link {
  padding: 0 !important;
}

#navbarScrollingDropdown {
  color: #b9b9b9;
}

.homeIcon {
  background-color: #fff;
  width: 80px;
  padding: 6%;
  border-radius: 50%;
}

#navbar {
  position: fixed;
  top: 0px;
  background-color: var(--p800);
  width: 100vw;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18), 0 10px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navbar {
  padding: 1.5% 0 !important;
}

.navBgChange {
  background-color: #fff !important;
}

.joinNews {
  font-size: 14px;
  font-weight: 500;
  padding-top: 12%;
}

.inputLabel {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.loaderSpin svg {
  margin: 2% 4%;
}

.emailInputAboutUs {
  padding: 3% 1% !important;
}

.navbar-dark .navbar-toggler {
  /* color: rgb(26 73 251 / 55%); */
  border-color: rgba(255, 255, 255, 0) !important;
}
.cornerSec{
  width: 25% !important;
}
/* .flexclass{
  display: flex;
  justify-content: end;
  align-items: center;
} */
.hidden {
  display: none !important;
}
@media screen and (max-width: 600px) {
  .contact_banner{
    padding-top: 30% !important; /*DK*/
  }
}

@media screen and (max-width: 400px) {
  .banner-image-contact {
    height: 585px !important;
    width: 100%;
  }
  .bannerTextBg {
    height: 670px !important;
  }
  .blogTextBg {
    height: 670px !important;
    padding: 40% 10% !important;
  }
}

@media screen and (max-width: 1220px) {
  .navbar {
    padding: 1.5% !important;
  }
  .navIcon{
    position:inherit !important;
  }
}
@media screen and (max-width: 900px) {
  .navbar {
    padding: 2.5% !important;
  }
  .loaderSpin{
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
 
}

@media screen and (min-width: 1500px) {
  .bannerTextBg {
    padding: 10% !important;
  }
}
@media screen and (max-width: 900px) {
  .bannerTextBg {
    padding: 25% 5% !important;
  }
}
@media screen and (min-width: 769px) {
  .headerNavLink {
    font-size: 15px !important;
    padding: 14px 26px !important;
  }
  .mobView {
    display: none !important;
  }
  .sectionPadding {
    padding: 5% 10%;
  }
  .processModel{
    padding: 5% 10%;

  }
}

@media screen and (max-width: 991px) {
 
  .navIcon {
    top: 5vh !important;
    width: 10em !important;
  }
  /* .navbar {
    background-color: #3e3e3e !important;
  } */
  .navbar{
    background-color: #fff !important;
  }
 .headerNavLink{
  color: black !important;
 }
 .headerNavLinkService{
  color: black !important;
 }
  .navbar-collapse {
    padding-top: 5%; /*dk*/
    padding-left: 7px; /*dk*/
  }
  .homeIcon {
    background-color: #fff;
    width: 80px;
    padding: 3%;
    border-radius: 50%;
  }
  .emailInputAboutUs {
    padding: 5% 10% !important;
  }
  .emailInputAboutUs input {
    height: 50px !important;
    padding: 0 3%;
  }
  .sectionPadding {
    padding: 5%;
  }
  .processModel{
    padding: 5%;
  }
  .verticalCardFull {
    margin-top: 20% !important;
  }
  .vcardButton {
    /*DK*/
    top: 5% !important;
  }

  .headerNavLink {
    font-size: 15px !important;
    padding: 14px 0px !important;
  }
  .ctaHead {
    text-align: center !important;
    padding-bottom: 0.8rem !important;
  }
  .contactLeftText {
    text-align: center !important;
  }
  .termsHead {
    padding-top: 25% !important;
  }
  .deskView {
    display: none !important;
  }
  .mobileview_div{
    display: flex;
    justify-content: center;
  }

  .mobView{
    padding-top: 20px;
    display: block !important;
    text-align: center;
  }
  .blogTextBg {
    padding: 25% 10% !important;
  }
 
}
.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding-bottom: 1rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.navBg {
  background-color: #00000000;
}

.dropdown-menu {
  /* background-color: #3e3e3e !important; */
  font-size: 14px !important;
}

.dropdown-menu a {
  text-decoration: none !important;
}

.dropdown-menu a:hover {
  /* background-color: #0b66f2; */
}
.headerNavLinkService:hover{
  font-weight: 600 !important;
color: #0c5dff !important;
}
.headerNavLink:hover{
  font-weight: 600;
}
.dropdown-item {
  text-decoration: none !important;
  /* color: #b9b9b9 !important; */
  color: black !important;
}

.iconNext {
  width: 30px;
  margin-left: 10px;
  transform: rotateY(180deg);
}

.iconNextRev {
  width: 30px;
  margin-left: 10px;
}

.iconBack {
  width: 30px;
  margin-right: 10px;
}

.navIcon {
  top: 3vh !important;

  width: 10em !important;
}

.headerNavLink {
  font-size: 15px !important;
  padding: 14px 26px;
}

.bannerTextBg {
  width: 100%;
  height: 580px;
  padding: 15%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}

.contactBg {
  background: linear-gradient(
    222.26deg,
    rgba(15, 37, 87, 0.57) 1.22%,
    #120231 106.28%
  );
}

.goDigitalPadding {
  padding: 5% 10% 2% 10%;
}

.blogTextBg {
  width: 100%;
  height: 580px;
  padding: 15%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  background: none;
}

.blogHead {
  font-size: 20px;
  font-weight: 600;
  padding-left: 0 !important;
}

.blogtag {
  background-color: #0c5dff;
  padding: 0.5% 2%;
  color: #fff;
  font-size: 12px;
  width: 12%;
  margin-top: -6%;
  z-index: 999;
}

.blogtag2 {
  background-color: #0c5dff;
  padding: 0.5% 2%;
  color: #fff;
  font-size: 12px;
  width: 12%;
  margin-top: -12%;
  z-index: 999;
}

.responsive-headline {
  font-size: 42px;
  font-weight: 800;
  color: #ffffff;
  text-align: center;
}

.banner-text h2 {
  font-size: 18px;
  text-align: center;
  padding: 2% 8% 1%;
  font-weight: 500;
  line-height: 1.5;
}

.dtlink {
  text-decoration: none !important;
  color: #429bf7 !important;
  font-size: 17px !important;
  font-weight: 200 !important;
}

@media screen and (max-width: 1024px) {
  .about-banner{
    padding-top: 5% !important;
  }
  .blogTextBg {
    padding: 25% 10% !important;
  }
.lastImg{
  margin-top: -7% !important;
}
  .navIcon {
    top: 6vh !important;
  }
}
@media screen and (max-width: 1210px) {
  .navIcon {
    top: 6vh !important;
  }
}
@media screen and (max-width: 1092px) {
  .navIcon {
    top: 6vh !important;
  }
}

.exploreMore {
  text-decoration: none !important;
  color: #fff !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  padding-top: 8%;
}

@media screen and (min-width: 1120px) {
  .homeSectionMargin {
    margin-top: 3rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 1119px) {
  .bannerTextBg {
    width: 100%;
    height: 585px;
    padding: 20% 5%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
  }
  .homeSectionMargin {
    margin-top: 3rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .vcardSubTextFull {
    width: 90% !important;
  }
  .responsive-headline {
    font-size: 22px;
    font-weight: 800;
    color: #ffffff;
    text-align: center;
  }
  .digital5 {
    /* width: 60% !import
    ant; */
    margin: 2% 0%;
    position: relative;
  }
  .digital5text {
    position: absolute;
    /* top: 15% !important;
    left: 21% !important; */
    color: #fff;
    background-color: rgba(11, 80, 118, 0.77);
    padding: 2% 4%;
  }
  
  .footerNav {
    padding-top: 10% !important;
    padding-left: 0;
  }

  .lev1 {
    font-size: 14px !important;
    width: 33% !important;
  }

  .lev2 {
    font-size: 12px !important;
    width: 33% !important;
    font-weight: 300;
  }

  .whyIdeaImg {
    margin-top: 0% !important;
  }

  /* .lev3 {
    width: 50% !important;
  } */

  .sectionHeadText2 {
    padding-top: 0;
  }

  .planCard {
    margin-top: 12% !important;
    border-top: 1px solid #00000010;
  }
  .goDigitalPadding {
    padding: 5% 10% 2% 10%;
  }
  .contactPara2 {
    padding: 3% !important;
  }
}

.planCard {
  margin-top: 0.25rem;
}

.digital5 {
  width: 100%;
  margin: 2% 0%;
  position: relative;
}

.digital5text {
  position: absolute;
  top: 10%;
  left: 4%;
  color: #fff;
  background-color: rgba(11, 80, 118, 0.77);
  padding: 2% 4%;
}

.whiteCircle {
  background-color: #fff;
}

.iconHead {
  font-size: 18px;
  text-align: center;
  padding-top: 4%;
}

.iconPara {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.CTAButton button {
  font-size: 14px !important;
  padding: 1.5% 3.5% !important;
}

.infiImg {
  padding: 5% !important;
}

.sec2Img {
  padding: 0% 3% 3% 0;
}

.marginImg {
  padding: 0% 3% 3% 0 !important;
}

.sectionPadding {
  padding: 5% 4% !important;
}

@media screen and (max-width: 1220px) {
  .sectionParaText {
    width: 100% !important;
    text-align: justify;
  }
  .sec2Img {
    padding: 5% !important;
    margin-top: 5% !important;
  }
  .marginImg {
    /* margin-top: -11% !important; */
  }
}

@media screen and (min-width: 820px) {
  .banner-text {
    padding-top: 0%;
  }
  
}

@media screen and (min-width: 1485px) {
  .banner-text {
    padding-top: 0%;
  }
}

@media screen and (min-width: 1695px) {
  .banner-text {
    padding-top: 2%;
  }
  .homeIcon {
    height: 100px !important;
    width: 100px !important;
  }
}

@media screen and (min-width: 2240px) {
  .banner-text {
    padding-top: 0%;
  }
  .about-banner {
    padding-top: 0% !important;
  }
}

@media screen and (min-width: 2695px) {
  .bannerTextBg {
    padding-top: 7% !important;
  }
}

@media screen and (min-width: 3433px) {
  .bannerTextBg {
    padding-top: 4% !important;
  }
  .banner-text {
    padding-top: 2%;
  }
}

.sectionPadding a {
  text-decoration: none !important;
  color: #429bf7;
  font-size: 17px;
  font-weight: 200;
}

@media screen and (max-width: 1220px) {
  .sectionHeadText1 {
    font-size: 34px !important;
  }
  .sectionHeadText2 {
    font-size: 32px !important;
  }
}

.sectionHeadText1 {
  font-weight: bold;
  font-size: 44px;
  color: rgba(187, 187, 187, 0.38);
  font-weight: 700;
}

.sectionHeadText2 {
  font-weight: bold;
  font-size: 40px;
  color: #313131;
  font-weight: 700;
}

.sectionParaText {
  color: #6c6c6c;
  font-size: 16px;
  font-weight: 400;
  width: 85%;
  padding: 3% 0 0 0;
  text-align: justify;
}

.sectionParaText1 {
  color: #6c6c6c;
  font-size: 18px;
  font-weight: 300;
  width: 85%;
  padding: 3% 0 0 0;
  text-align: justify;
}

.aboutMissionHeadText {
  font-weight: 500;
  font-size: 22px;
  /*color: #313131;*/
  color: #fff;
  padding: 0 6%;
}

@media screen and (min-width: 576px) {
  .modal-dialog {
    max-width: 440px;
    margin: 1.75rem auto;
  }
}

.missionParaText {
  /*color: #6c6c6c;*/
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  width: 75%;
  padding: 3% 0 0 0;
}

.missionDiv {
  padding: 8% 2%;
}

.valuesText {
  color: #6c6c6c;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.valuesHeadText {
  font-weight: 700;
  font-size: 18px;
  color: #313131;
  margin-top: 8px;
}

.LearnButton {
  border: 1px solid #0c5dff !important;
  box-sizing: border-box !important;
  border-radius: 15px 0px !important;
  padding: 2% 4% !important;
}

.LearnButton:hover {
  background-color: #0c5dff !important;
  color: #fff !important;
}

.blogImg {
  height: 234px;
}

.blogImg2 {
  height: 120px;
}

.blogtag3 {
  background-color: #0c5dff;
  padding: 0.5% 2%;
  color: #fff;
  font-size: 12px;
  width: 50%;
  /* margin-top: -12%; */
  z-index: 999;
}

.blogHeadText2 {
  font-size: 15px;
  font-weight: 500;
  color: #414141;
}

.subCatagoryList {
  display: inline;
  font-size: 18px;
  font-weight: 400;
  margin: 0 2%;
  color: #1b1a1a;
}

.blogDetailsHead {
  font-size: 20px;
  font-weight: 600;
  color: #414141;
  margin: 5% 0 2% 0;
}

.blogContent {
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: justify !important;
}

.footerBg {
  /* background-color: #3e3e3e; */
  /* background: #00172C; */
  background-color: #0A1539;

  color: #fff;
  padding: 5% 3%;
}

.footdesc {
  font-size: 12px;
  color: #fff;
  /* font-weight: 300; */
  font-weight: 400;
  width: 80%;
  padding-top: 3%;
}

.footerNav {
  list-style: none;
  padding-top: 25%;
  font-size: 13px;
  font-weight: 400;
  line-height: 2.5em;
}

.footerNav a {
  text-decoration: none !important;
  color: #fff;
}

.subserviceCard {
  margin: 2%;
  border: 1px solid #9c9c9c;
  padding: 5%;
  text-align: center;
  height: 100%;
}

.custCol:hover {
  cursor: pointer;
}

.subserviceCard img {
  padding: 20% 35%;
}

.BlogHeadText {
  font-size: 16px;
  font-weight: 500;
  color: #414141;
}

.BlogTime {
  font-size: 14px;
  font-weight: 400;
  color: #787777;
}

.BlogDesc {
  font-size: 14px;
  font-weight: 300;
  color: #787777;
}

.ReadMore {
  color: #0c5dff;
  font-size: 16px;
  font-weight: 500;
}

.contactformbg {
  background-color: #041338f7;
  padding: 5% 8% 5% 8% !important;
}

.contactLabel {
  color: #fff;
  padding-top: 5%;
  font-weight: 400;
  font-size: 16px;
}

.btn-close {
  background-color: #fff !important;
}

.contactLabel2 {
  color: #e0e0e0;
  padding-top: 5%;
  font-weight: 400;
  font-size: 16px;
}

.contactForm input {
  background: none !important;
  border: none !important;
  border-bottom: 1px solid #9c9c9c !important;
  border-radius: 0 !important;
  width: 100% !important;
}

.contactForm textarea {
  background: none !important;
  border: none !important;
  border-bottom: 1px solid #9c9c9c !important;
  border-radius: 0 !important;
  width: 100% !important;
}

.serviceImg {
  height: 180px;
  background-size: cover;
}

.verticalCard {
  height: 500px;
  position: relative;
  background-position: center;
}

.verticalCardFull {
  height: 500px;
  position: relative;
}

.overlay {
  background-color: #151c3f;
  position: absolute;
  opacity: 0.5;
  -webkit-transition: background-color 2s ease-in, opacity 2s ease-in;
  transition: background-color 2s ease-in, opacity 2s ease-in;
}

.verticalCard:hover .overlay {
  /* background-color: #151C3F; */
  /* opacity: 0.8; */
  -webkit-transition: background-color 1s ease-in, opacity 1s ease-in;
  transition: background-color 1s ease-in, opacity 1s ease-in;
  cursor: pointer;
}

.verticalCardFull:hover {
  cursor: pointer;
}

.vcardText {
  position: absolute;
  bottom: 5%;
  left: 10%;
  z-index: 999;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.contactPara2 {
  padding-left:  15%;
  padding-right:  15%;
  padding-top: 0;
  padding-bottom: 3%;
}

.vcardSubText {
  font-size: 17px;
  font-weight: 400;
  text-align: left;
  padding-top: 4%;
  width: 90%;
}

.vcardSubTextFull {
  font-size: 17px;
  font-weight: 400;
  text-align: left;
  padding-top: 1%;
  width: 100%;
}

.verticalCard:hover .vcardText {
  bottom: 10%;
  -webkit-transition: background-color 1s ease-in, opacity 1s ease-in;
  transition: background-color 1s ease-in, opacity 1s ease-in;
}

.planHead {
  font-size: 25px;
  font-weight: 400;
}

.planIcon {
  font-size: 60px !important;
}

.planList li {
  margin-bottom: 5%;
  padding: 2% 0;
  border-bottom: 1px solid #9c9c9c30;
}

.servCard:hover {
  border: 2px solid #0c5dff !important;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15) !important;
  cursor: pointer;
}

.servCard {
  height: 380px;
}

.check {
  bottom: 0;
  left: 0;
}

.serverCardText {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.backServ:hover {
  cursor: pointer;
}

.price-icon {
  width: 18%;
  padding: 5% 2% 2% 2%;
}

#siteTitle {
  margin-right: auto;
  margin-left: 5%;
  color: white;
  font-size: 40px;
}

.planCard:hover {
  box-shadow: 0px 36px 72px rgba(4, 9, 33, 0.12);
}

.vcardButton {
  position: absolute;
  top: 5%;
  right: 10%;
  z-index: 999;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.vcardDisplay {
  display: none !important;
}

.servSubHead {
  padding: 1% 5%;
  color: #383838;
  font-weight: 300;
  text-align: justify;
}

.menueBtn {
  margin: 8px;
  padding: 8px;
  font-size: 19px;
  align-content: center;
  background: var(--a700);
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18), 0 10px 10px 0 rgba(0, 0, 0, 0.2);
  color: black;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}

.menstrip {
  position: absolute;
  width: 36px;
  height: 4px;
  background: var(--p800);
  margin: 4px 0;
  opacity: 1;
  transform: rotate(0deg);

  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}
#exp {
  width: 56px;
  height: 56px;
  right: 100px;
}
.exp1 {
  top: 20px;
  transition-property: top;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}
.exp2 {
  top: 32px;
}
.exp3 {
  top: 44px;
  transition-property: top;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

.toprotate {
  animation-name: rotateleft;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  top: 32px;
  transform: rotate(45deg);
}
@keyframes rotateleft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(45deg);
  }
}
@keyframes rotateright {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-45deg);
  }
}
.bottomrotate {
  animation-name: rotateright;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  top: 32px;
  transform: rotate(-45deg);
}
.fadeout {
  opacity: 0;
}

#nav-links {
  display: -webkit-flex;
  display: flex;
  font-size: 16px;
  width: 50%;
  justify-content: space-around;
  margin-right: 30px;
}
#nav-collapsed {
  display: none;
  margin-right: 30px;
}

.nav-open {
  position: fixed;
  top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--p800);
  z-index: 1;
  align-items: center;
  overflow-y: hidden;
  max-height: 240px; /* approximate max height */

  transition-property: max-height;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}
.close-box {
  max-height: 0;
}

.anchor {
  position: relative;
  display: block;
  top: -100px;
}

#center-stripe {
  position: fixed;
  background: var(--p500);
  width: 100%;
  position: absolute;
  top: 80px;
  min-height: 100%;
  height: 1 0;
}

.about,
.portfolio,
.contact,
.blog {
  background: rgba(256, 256, 256, 0.9);
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18), 0 10px 10px 0 rgba(0, 0, 0, 0.2);
  margin: 0px 5% 20px 5%;
  padding: 8px 5%;
  font-size: 24px;
}
.about {
  margin-top: 20px;
}
.portfolio {
  font-size: 16px;
}

.aboutText {
  width: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.about .twocols {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.circle {
  position: relative;
  border-radius: 50%;
  width: 30vw;
  height: 30vw;
}
.mbRes{
  margin-bottom: -10% !important;
}
@media screen and (max-width: 800px) {
  .about .twocols,
  .portfolio .twocols {
    flex-direction: column;
    justify-content: center;
  }
  #nav-links {
    display: none;
  }
  #nav-collapsed {
    display: block;
  }
  #siteTitle {
    font-size: 32px;
  }
  .circle {
    width: 50vw;
    height: 50vw;
  }
  .servCard {
    height: 400px;
  }
}

.sectionTitle {
  width: 100%;
  text-align: center;
}
.twocols {
  display: -webkit-flex;
  display: flex;
  align-items: top;
  flex-flow: row;
}
.website,
.article {
  position: relative;
  background: white;
  margin: 5%;
  border-radius: 10px;
  width: 90%;
  padding: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18), 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.article {
  width: 100%;
  margin: 5% 0;
}
.content {
  font-size: 18px;
}

.webpic {
  width: 100%;
  margin-bottom: 5%;
  border-radius: 10px;
}
#projs {
  width: 50%;
}

.p {
  font-size: 18px;
}

@media screen and (max-width: 800px) {
  #projs {
    width: 100%;
  }
}

img {
  width: 100%;
  height: auto;
}

.blackLink {
  color: black;
  text-decoration: inherit; /* no underline */
}

.blackLink:hover {
  text-decoration: inherit; /* no underline */
}

/*Darshan*/

@media screen and (max-width:1000px) {
  .contact_tab{
    /* width: 50% !important; */
    text-align: left !important;
  }
  .contact_tab>a{
text-align: left !important;
  }
  
  .home_secondSecCont{
    padding-left: 0% !important;
  }
  .home_col{
    flex-direction: column !important;
    align-items: center;
  }
  .home_col>div{
    width: 100%;
  }
   .digital5text {
    left: 35.3% !important;
}
.digital5 {
  width: 30% !important;
}
  .home_col1{
    margin-left: 0% !important;
  }
  .about-banner {
    padding-top: 5% !important;
  }
 
  .home_secondSec>div{
    text-align: center;
  }
 
  .sectionPadding{
    text-align: left !important;
  }
  
  .text-center1{
    text-align: center !important;
  }
 
  .marginImg>img{
    max-width: 100% !important;
    text-align: end;
  }
  .tab_para{
    padding-top: 0 !important;
    margin-left: 0!important;
  }
  
  .sec2Img{
    margin-left: 0 !important;
  }
 

  .home_fithsec{
      flex-direction: column;
  }
  
   .home_sixthsec{
    justify-content: space-between !important;
    
   }
   .sixthsec_cont{
    margin-top: -9% !important;
    width: 70% !important;
   }

   .sixthsec_cont{
    margin-top: 0 !important;
    margin-left: 0 !important;
   }
  
  .footer_form{
    display: flex;
  }
  .footer_div{
    padding-right: 0px !important;
  }
.mobView{
  width: 70% !important;
}


}

@media screen and (max-width:980px) {
.navIcon{
  position: relative !important;
  top: 0% !important;
}


}
@media screen and (max-width:768px) {
  .tab_para{
    margin-left: 0 !important;
  }
   .about-banner{
    padding-top:  10% !important;
  }
 
}

@media screen  and (max-width:767px) {
  .contact_tab{
  text-align: center !important;
  }
  .footdesc{
width: 100% !important;
  }
 
  .lastImg{
    margin-top: -11% !important;
  }
 
  .cornerSec{
    width: 100% !important;
  }
  .sixthsec_cont{
    margin-top: 5% !important;
  }
  
  .cornerSec>img{
    height: auto !important;
  }
  .home_fourthsec>div {
    width: 100% !important;
  }
  .home_thirdsec1>div{
    width: 100% !important;
  }
  .third_Sec>div{
width: 100% !important;
  }
  .home_thirdsecContent{
    padding-left: 4% !important;
    padding-right: 4% !important;
  }
.mob_text{
padding-right: 4% !important;
}
  .home_thirdsec1{
    flex-direction: column !important;
  }
 .home_thirdsec1>div{
  width: 100% !important;
 }
  .home_fourthsecCont{
    width: 100% !important;
  }
 
  .home_secondSecCont{
    padding-left: 4% !important;
  }
  .home_thirdsec{
    flex-direction: column !important;
  }

  .home_thirdsec>div{
    width: 100% !important;
  }
 
  .home_fourthsecCont{
    padding-left: 4% !important;
  }
   .sixthsec_cont{
    width: 100% !important;

   }
   .paraEight{
text-align: justify !important;
   }
   
  
}

@media screen and (max-width:500px) {

   
  .home_fourthsec>div {
    width: 100% !important;
  }
  .home_thirdsec1>div{
    width: 100% !important;
  }
 
  .home_fourthsecImg{
    width: 100% !important; 
  }
  .home_fourthsecCont {
    padding: 5% 10%;
  }
  .mobView{
    width: 100% !important;
  }
  .home_thirdsec{
    flex-direction: column !important;
  }
  .infiImg>img{
    max-width: 100%;
  }
  .marginImg>img{
    max-width: 100% !important;
  }
  .sec2Img>img{
    max-width: 100%;
  }
  
  .iti_secondsec{
    flex-direction: column-reverse !important;
  }
}
.br{
  display: none;
}

@media screen and (max-width:540px) {
  .digital5{
    width: 60% !important;
  }
  .digital5text{
    left: 21% !important;
  }
}
@media screen and (max-width:500px) {
  .contactformbg{
    margin-top: 0% !important;
  }
  .digital5{
    width: 60% !important;
  }
   .digital5text{
    left: 21% !important;
  }
  .home_thirdsec{
    flex-direction: column !important;
  }
  .infiImg>img{
    max-width: 100%;
  }
  
  .marginImg>img{
    max-width: 100% !important;
  }
  .sec2Img>img{
    max-width: 100%;
  }
  .cornerSec>img{
    max-width: 100%;
  } 
  .iti_secondsec{
    flex-direction: column-reverse !important;
  }
}

@media screen and (max-width:1024px) {
 

  .marginTop{
    margin-top: -11% !important;
  }

  .cornerSec>img{
    height: 80%;
  }

  .home_fourthsec >div {
    width: 50% ;
  }
  .home_thirdsec1>div{
    width: 50% ;
  }
/* .sectionPadding{
  padding-bottom: 10% !important;
} */
.home_thirdsec{
  justify-content: space-around !important;
}
.home_thirdsecContent{
  width: 50% !important;
  margin-left: 0px !important;
  padding: 30px 2% !important;
  
}
.home_thirdsecImg{
  width: 50% !important;
padding-left: 0px !important;
padding-right: 0px !important;
}
.home_fourthsec{
  justify-content: space-around !important;
 }
 .sec2Img{
  margin-left:  0 !important;
 }
 
 .home_thirdsec1{
  justify-content: space-around !important;
 }
.lastImg{
  width: 50% !important;
/* padding-left: 0!important; */
}
.lastCont{
  margin-left: 0 !important;
  padding-top: 5% !important;
  padding-right: 0px !important;
  }

}

@media screen 
and (min-width: 1001px) 
and (max-width: 1024px){
  .processModel{

  }
}
@media screen 
and (min-width: 300px) 
and (max-width: 767px){
  .mobPad{
    padding-bottom: 3rem !important;
  }


  .bannerTextBg {
    padding: 35% 5% !important;
}
.aboutbannerText{
  padding: 45% 5% !important;

}
.missionMob_two{
padding-bottom: 0% !important;
}
.missionMob{
  padding: 7% 6% !important;
}
}

@media screen 
and (min-width: 1001px) 
and (max-width: 1024px){
  .home_col{
    justify-content: center !important;
  }
  .home_col1{
    margin-left: 0% !important;
    
  }
  .home_col>div{
    width: 19% !important;
  }
}
@media screen 
  and (min-width: 768px) 
  and (max-width: 1024px){
.cornerSec{
    width:35% !important;
  }
  .sixthsec_cont{
    width: 65% !important;
    margin-left: 0 !important;
  }
 

}

@media screen 
  and (min-width: 820px) 
  and (max-width: 840px){
    .blogTextBg {
      padding-top: 30% !important;
    }
    .about-banner{
      padding-top: 5% !important;
    }
  }
  @media screen 
  and (min-width: 800px) 
  and (max-width: 1000px){
    .about-banner{
      padding-top:  7% !important;
    }
  }
@media screen 
  and (min-width: 768px) 
  and (max-width: 1000px){
    .email_aboutus{
      justify-content: space-evenly !important;
      
    }
    .email_text{
      margin-left:  0% !important;
    }
   .emailInputAboutUs{
    padding-left: 0 !important;
    padding-right: 0 !important;
   }
 .input, input::-webkit-input-placeholder {
  font-size: 13px !important;
 }
   .contatcus_btn{
      width: 100% !important;
   }
    .sixthsec_cont{
    margin-top: -10% !important
    
    }
    
  }
  @media screen 
  and (min-width: 280px) 
  and (max-width: 300px){
   
  }
  @media screen and (max-width:280px) {
    .CTAButton{
      padding-left: 0px !important;
      padding-right: 0px !important;

    }
    .CTAButton button {
      font-size: 12px !important;
    }
    .aboutbannerText{
      padding: 40% 5% !important
    }
    .navbar-brand{
      width:50%
    }
   .homeResponsiveHeadline{
    font-size: 20px;
    padding-top: 5%;
   }
   .homeResponsiveText{
    font-size:17px ;
   }
   .CTAButton{
    padding-bottom: 20px;
   }
   .digital5text{
    font-size: 12px;
   }
    
  }
  @media screen 
  and (min-width: 540px) 
  and (max-width: 760px){
    .contact_banner{
      padding-top: 20% !important;
    }
    .missionMob {
padding-bottom: 0 !important;
    }
    .about-banner{
      padding-top: 0% !important;
    }
    .cornerSec>img {
      height: 80% !important;
    }
    .sixthsec_cont {
      margin-top: -40% !important;
  }
  .mobView{
    width: 85% !important;
  }
    .infiImg{
      width: 80% !important;
    }
    .home_SecondSecMob{
      justify-content: center;
    }
    .homeThirdMob>img{
      padding-left: 120px !important;
    }
   .sectwoImg
    {
      padding-left: 40px;
      padding-right: 40px;
   }
   .sec3img{
    padding-left: 40px;
      padding-right: 40px;
   }
   .digital5 {
    width: 50% !important;
   }
   .digital5text {
    left: 25.9% !important;
}
  }



@media screen and (max-width:420px) {
  .digital5text{
    left:21.4% !important
  }
}

@media screen 
and (min-width: 768px) 
and (max-width: 1000px){
  .custCol-font{
    font-size: 14px !important;
  }
} 
 @media screen and  (max-width:1024px) {
  .iti_para{
    width: 50% !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
  }
  .iti_imgsec{
    width: 50% !important;
    padding: 2rem !important;
  }
  .digital_Sec{
    width: 50% !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
  }
  .digital_SecImg{
    width: 50% !important;
    padding-right: 0 !important;
  }
  .p-5 {
    padding: 2rem !important;
  }
  .btn_res{
    width: 26% !important;
  }
  .cards-res{
    width: 33% !important;
  }
  .serviceImg-res{
    margin-top: -20% !important;
  }
  .lev3{
    width: 25% !important;
  }
  .fontRes{
    font-size: 15px !important;
  }
  .mbRes{
    margin-bottom: -13% !important;
  }
}


  
@media screen and (max-width:768px) {
  .btn_res{
    margin-left: 70% !important;
    width: 35% !important;
  }
  .cards-res{
    width: 50% !important;
  }
}

@media screen and (max-width:820px) {
  .cards-res{
    width: 50% !important;
  }
}
@media screen and (max-width:1000px) {
  .btn_res{
    margin-left: 70% !important;
    width: 35% !important;
  }
}

@media screen and (max-width:700px) {
  .iti_para{
    width: 100% !important;
    padding-right:4% !important;
  }
  .iti_imgsec{
    width: 100% !important;
  }
  .digital_Sec{
    width: 100% !important;
    padding-right:4% !important;
  }
  .digital_SecImg{
    width: 100% !important;
  }
  .btn_res{
    margin-left: 0% !important;
    width: inherit !important;
  } 
  .cards-res{
    width: 100% !important;
  }
  .lev3{
    width: 50% !important;
  }
  #scroll{
    padding-top: 5% !important;
    scroll-behavior: smooth;
    
    /* margin-top: -9% !important; */
    /* background-color: red;
    width: 100%; */
  }
  .subserviceCard{
    font-size: 14px !important;
  }
  .fontRes{
    font-size: 14px !important;
  }
  .lev3{
    width: 45% !important;
  }
  .main-container{
   justify-content: center;
  }
  .custCol-font{
    font-size: 13px !important;
  }
  .mbRes{
    margin-bottom: -35% !important;
  }
  .br{
    display: block;
  }
}

.modal-content{
border-radius: none;
}

.bannerMod{
  height: 100%;
  padding-left: 15%;
  padding-right: 15%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width:1000px)  {
  .bannerMod{
    padding-top: 12% !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .banner-text{
    padding-top:  0 !important;
  }
}
/* @media  screen and (max-width:768px) {
  .bannerMod{
    padding-top: 95px !important;
  }
  
} */
@media screen and (max-width:700px)  {
  .bannerMod{
    padding-top: 18% !important;
  }
}
.bold{
  font-weight: bold;
}
.breadcrumb-item+.breadcrumb-item::before {
  padding-right: 0 !important;
}

@media screen and (max-width:980px) {
  .navBg{
    background-color: #fff !important;
  }
 .headerNavLink{
  color: black !important;
 }
 .headerNavLinkService{
  color: black !important;
 }
 .dropdown-item{
  padding-left: 25px !important;
 }


}

.navbar-dark .navbar-toggler-icon {
    background-image: url("../src/img/hamburger.svg") !important ;
  /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important; */
}

  /* .navbar-toggler-icon {
    width: 2em !important;
    height: 2em !important;
  } */

.copyRightFooter{
  background-color: #fff;
color: black;
}

.dropdown-item:active {
  background-color: rgb(185, 185, 185) !important;
}
.modal-header{
  background-color: #0A1539 !important;
}
.modal-submit{
  background-color: #0A1539 !important;
}
.subService:hover{
   font-weight: 600;
   color: #0c5dff !important;
}

.navbar-dark .navbar-toggler {
  color: none !important;
}
/* .iti_imgsec{
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 0.75rem !important;
} */


.dropdown-menu {
    border-top: 2px solid blue !important;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    border-radius: 0 0 0.25rem 0.25rem !important;
    box-shadow:  0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}


@media screen and (max-width:990px) {
  .dropdown-menu {
    border: none !important;
    border-radius: none !important;
    box-shadow: none!important;
    padding: 0 !important;
    }
 
}


.dropdown-item:hover {
  background-color: transparent !important;
}

.nav-active{
  color:#0d6efd !important;
}

.dropdown-menu{
  width: 18rem !important;
}
.headerNavLink.text-underline.test.nav-item.show.dropdown {
  padding-bottom: 8px !important;
}
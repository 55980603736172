.verticalCard1 {
    width: 100%;
    padding: 0;
    background-image: url("../img/fw4.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width: 500px) {
    .verticalCard1 {
        background-image: url("../img/cloud2.png");
    }
}
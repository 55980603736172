.breadcrumb {
    background-color: white;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    padding-top: 2rem !important;
  }
  
  .breadcrumb-item {
    color: grey;
  }
  
  .breadcrumb-item a {
    color: inherit;
    text-decoration: none;
  }
  .breadcrumb-item a:hover{
    color: #6c757d;
     text-decoration: underline;
  }
  
  .breadcrumb-item span {
    color: black;
    font-weight: 500;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    float: left;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "") !important;
}
.breadcrumb-item+.breadcrumb-item {
  padding-left: 0rem !important;
}

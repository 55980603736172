
.headerNavLink {
  font-size: 125%;
  text-decoration: none !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #0c5dff !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 425px) {
  .centered {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .banner-image {
    height: 585px !important;
    width: 100%;
  }

  .banner-image-contact {
    height: 585px;
    width: 100%;
  }
}

.banner-image {
  height: 585px !important;
  width: 100%;
}

.banner-image-contact {
  height: 585px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .banner-image {
    min-height: 585px !important;
    width: 100%;
  }
  .banner-image-contact {
    height: 585px;
    width: 100%;
  }
  
  .banner-text {
    padding-top: 7%;
  }
  /* .about-banner {
    padding-top: 5% !important;
  } */
}


@media screen and (min-width: 1687px) and (max-width: 2000px) {
  .navIcon {
    width: 6%;
    top: -8px;
    position: absolute;
  }
}

@media screen and (max-width: 1301px) {
  .navIcon {
    width: 8%;
    top: -8px;
    position: absolute;
  }
}
@media screen and (min-width: 1151px) and (max-width: 1300px) {
  .navIcon {
    width: 9%;
    top: -8px;
    position: absolute;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1686px) {
  .navIcon {
    width: 8%;
    top: -8px;
    position: absolute;
  }
}
@media screen and (min-width: 1687px) and (max-width: 2000px) {
  .navIcon {
    width: 6%;
    top: -8px;
    position: absolute;
  }
}
@media screen and (min-width: 2001px) {
  .navIcon {
    width: 5%;
    top: -8px;
    position: absolute;
  }
}

.text-underline {
  position: relative;
  display: flex !important;
  justify-content: center;
}

.text-underline::after {
  content: '';
  position: absolute;
  /* left: 0; */
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: blue;
  /* transition: width 0.3s ease-in-out; */
}
.text-underline:hover::after {
  width: 30%;
}

@media screen and (max-width:991px) {
  .text-underline{
    display: block !important;
  }
  .text-underline::after{
   display: none !important;
  }
 
}